import cn from 'classnames';

import {DivHtml} from '@/components/shared/HtmlElements';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';

import Link from '../Link/Link';
import {ButtonTheme} from '../Button/types';
import Pill from '../Pill/Pill';

import type {BlockProps} from './types';

/**
 * @deprecated
 */
const Block = ({
  pill,
  heading,
  contentHtml,
  link,
  linkTheme = ButtonTheme.Dark,
  size = 'big',
}: BlockProps) => {
  return (
    <>
      {pill ? (
        <div className={cn('mb-3')}>
          <Pill {...pill} />
        </div>
      ) : null}
      {heading ? (
        <>
          <h3
            className={cn('font-bold', {
              'text-t3': !['big', 'small', 'xsmall'].includes(size),
              'text-5xl': size === 'xbig',
              'text-t5': size === 'big' || size === 'small',
              'text-t7': size === 'xsmall',
            })}
          >
            {heading}
          </h3>
          <Spacer size={size === 'xsmall' ? 'xs' : 'sm'} />
        </>
      ) : null}
      {contentHtml ? (
        <DivHtml
          className={cn({
            'text-body-lg': !['small', 'xsmall'].includes(size),
            'text-body-base': size === 'small',
            'text-sm': size === 'xsmall',
            richtext: true,
          })}
        >
          {contentHtml}
        </DivHtml>
      ) : null}
      {link?.url && (
        <>
          <Spacer size="sm" />
          <Link
            theme={linkTheme}
            href={link.url}
            componentName={link.name || ''}
          >
            {link.text}
          </Link>
        </>
      )}
    </>
  );
};

export default Block;
